import React, { useState } from 'react';
import Layout from '../components/layout';
import Card from '../components/Card';
import Seo from "../components/seo";

import JSONInterviewed from "../../content/interviewed.json";

import { motion } from 'framer-motion';

const Intervieweds = () => {

  // const allInterviewed = data.allInterviewedDataJson.nodes;
  const allInterviewed = JSONInterviewed;
  const [interviewedList, setInterviewedList] = useState(allInterviewed)

  const tabsArr = [];
  Object.keys(allInterviewed).forEach(function (key) {
    tabsArr.push(allInterviewed[key].categories);
  });
  const flattened = tabsArr.reduce((acc, curVal) => acc.concat(curVal), []);
  const uniqueTabs = [...new Set(flattened)];
  const interviewedTabs = ["Todos", ...uniqueTabs];
  const [filterTab, setFilterTab] = useState("Todos");
  const filter = (tab) => {
    setFilterTab(tab)
    if (tab === "Todos") {
      setInterviewedList(allInterviewed)
    } else {
      const filtered = allInterviewed.filter(interviewed => interviewed.categories.includes(tab));
      setInterviewedList(filtered) // set the filtered list to the state variable
    }
  }

  return (
    <Layout>
      <Seo title="Entrevistados" />
      <div className="page-wrapper">
        <div className="container">

          <div className="row">
            <div className="col">
              <h1 className="h3 ff-roboto fw-bold mt-4">Entrevistados</h1>

              <div className="filter">
                {
                  interviewedTabs.map((tab, index) => {
                    return (
                      <div
                        key={index}
                        role="button"
                        tabIndex={index}
                        className={`item ${filterTab === tab && 'active'}`}
                        onClick={() => filter(tab)}
                        onKeyDown={() => filter(tab)}>
                        {tab}
                      </div>
                    )
                  })
                }
              </div>

              <motion.div layout className="card-list">
                {
                  interviewedList.map((interviewed, index) => {
                    return (
                      <Card
                        key={index}
                        interviewed={interviewed}
                      />
                    )
                  })
                }
              </motion.div>

            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
}

export default Intervieweds;